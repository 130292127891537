
import {
  SearchIcon,
  MenuIcon,
  XIcon,
  UserCheckIcon,
  UserIcon,
  ChevronDownIcon,
} from 'vue-feather-icons';
import SubCategory from './SubCategory.vue';
import SubCategoryLookBook from './SubCategoryLookBook.vue';
import DropDownMenu from './DropDownMenu.vue';
import MiniCart from './MiniCart.vue';
import IconFavorite from './IconFavorite.vue';
import IconCart from './IconCart.vue';
import cartMixin from '@/mixins/cartMixin.js';
import favoriteMixin from '@/mixins/favoriteMixin.js';
import { LookBooksMenuQuery } from '~/graphql/queries/LookBooks';
import SubCategoryMark from './SubCategoryMark.vue';

export default {
  mixins: [cartMixin, favoriteMixin],
  props: {
    menuTransparent: {
      type: Boolean,
      default: false,
    },
  },
  async asyncData() {},
  data() {
    return {
      menuOpen: false,
      openSearch: false,
      drawer: false,
      lookBooks: null,
      linkWrapperHovred: -1,

      setMiniCartVisibily: function (elem) {
        elem = false;
      },
    };
  },
  activated() {
    // this.initUser();
  },
  apollo: {
    lookBooks: {
      query: LookBooksMenuQuery,
      variables() {
        return {
          lang: this.$i18n.locale,
        };
      },
    },
  },

  mounted() {
    this.cartService.fetchCartSummary();
    this.favoriteService.fetchFavorite();
    this.stickyState();
  },
  computed: {
    userSession() {
      return this.$auth.user.Customer;
    },
    topBannerDisable() {
      if (this.$SETTINGS.isMobile) {
        return this.$store.state.shared.topBanner.alwaysDisable;
      } else {
        return false;
      }
    },
    categoriesLinks() {
      const list = this.$store.state.shop.categories.list;
      return list;
    },
    soldCategories() {
      try {
        const list = this.$store.state.shop.categories.list.filter((item) => {
          return item.item.IdCagetory === '77';
        });
        if (list.length) {
          const menuList = list[0].item.SubCategories.map((item) => {
            return {
              text: item.CategoryName,
              link: this.localePath(`/${item.CategoryDescriptionFriendlyURL}`),
              type: 'left',
            };
          });
          return menuList;
        }
      } catch (error) {}

      return [];
    },
    menuIsLight() {
      return this.$store.state.shared.menu.isTransparent;
    },
    lookbookMenuItem() {
      const menuItem = {
        left: {},
        right: {},
      };

      if (this.lookBooks) {
        if (this.lookBooks.data) {
          const leftItem = this.lookBooks.data.find(
            (item) => item.attributes.positionInMenu === 'Left'
          );
          if (leftItem) {
            menuItem.left.image = leftItem.attributes.thumbnailImage.data
              ? this.$GLOBAL_VARIABLES.cmsUrl +
                leftItem.attributes.thumbnailImage.data.attributes.url
              : '';
            menuItem.left.text = leftItem.attributes.title;
            menuItem.left.link =
              '/lookbooks/' + leftItem.attributes.seo.permalink;
          }
          const rightItem = this.lookBooks.data.find(
            (item) => item.attributes.positionInMenu === 'Right'
          );
          if (rightItem) {
            menuItem.right.image = rightItem.attributes.thumbnailImage.data
              ? this.$GLOBAL_VARIABLES.cmsUrl +
                rightItem.attributes.thumbnailImage.data.attributes.url
              : '';
            menuItem.right.text = rightItem.attributes.title;
            menuItem.right.link =
              '/lookbooks/' + rightItem.attributes.seo.permalink;
          }
        }
      }
      return menuItem;
    },
    menuitemsStatic() {
      const menuiLinks = [
        {
          id: 6,
          text: this.$t('Our shops'),
          link: this.localePath(`/${this.$t('shops_url')}`),
          items: [],
          mobile: true,
        },
        // {
        //   id: 7,
        //   text: this.$t('Magazine'),
        //   link: this.localePath('/blogs'),
        //   items: [],
        //   mobile: true,
        // },
      ];

      return menuiLinks;
    },
    menuitems() {
      const menuLinks = [];
      this.categoriesLinks.map((item) => {
        const category = {
          id: item.item.IdCategory,
          text: item.text,
          link: item.link,
          mobile: false,
          items: [],
        };
        if (item.item.SubCategories) {
          item.item.SubCategories.map((item2, index) => {
            const subCategories = [];
            if (item2.SubCategories) {
              item2.SubCategories.map((item3) => {
                subCategories.push({
                  text: item3.CategoryName,
                  link: this.localePath(
                    `/${item3.CategoryDescriptionFriendlyURL}`
                  ),
                  item: item3,
                });
              });
            }

            if (index === 0) {
              category.items.push({
                text: item2.CategoryName,
                link: this.localePath(
                  `/${item2.CategoryDescriptionFriendlyURL}`
                ),
                type: 'left',
                items: subCategories,
              });
              subCategories.map((item4) => {
                category.items.push({
                  text: item4.item.CategoryName,
                  link: this.localePath(
                    `/${item4.item.CategoryDescriptionFriendlyURL}`
                  ),
                  type: 'left',
                });
              });
            } else {
              category.items.push({
                text: item2.CategoryName,
                link: this.localePath(
                  `/${item2.CategoryDescriptionFriendlyURL}`
                ),
                type: 'center',
                items: subCategories,
              });
            }
          });
        }
        menuLinks.push(category);
      });

      return menuLinks;
    },
    lookbooks() {
      return {
        text: this.$t('Lookbooks'),
        link: this.localePath('/lookbooks'),
        type: 'right',
        items: this.lookBooks?.data || [],
      };
    },
    newsCategory() {
      const category = this.categoriesLinks.find(
        (item) => item.item.IdCagetory === '37'
      );

      if (category) {
        return category;
      }

      return {
        text: this.$t('News'),
        link: '/',
      };
    },
    collectionCategory() {
      const category = this.categoriesLinks.find(
        (item) => item.item.IdCagetory === '53'
      );

      if (category) {
        return category;
      }

      return {
        text: this.$t('Collection'),
        link: '/',
      };
    },
    lastChanceCategory() {
      const category = this.categoriesLinks.find(
        (item) => item.item.IdCagetory === '44'
      );

      if (category) {
        return category;
      }

      return {
        text: this.$t('Last chance'),
        link: '/',
      };
    },
    accessoiresCategory() {
      const category = this.categoriesLinks.find(
        (item) => item.item.IdCagetory === '24'
      );

      if (category) {
        return category;
      }

      return {
        text: this.$t('Accessories'),
        link: '/',
      };
    },
    soldeCategory() {
      const category = this.$store.state.shop.categories.list.find(
        (item) => item.item.IdCagetory === '77'
      );

      if (category) {
        return category;
      }

      return {
        text: this.$t('Summer Sales'),
        link: '/',
      };
    },
  },
  methods: {
    initUser() {},
    openSearchBlock() {
      this.$emit('openSearch');
    },
    openRightBar(tabActive) {
      this.favoriteService.fetchFavorite();
      this.$store.commit('shared/menu/toggle', {
        tabActive,
        open: true,
      });
    },
    fetshFavoriteAndCart() {
      // this.cartService.fetchCart();
      // this.favoriteService.fetchFavorite();
    },
    openMiniCart() {
      this.cartService.fetchCart();
      this.openRightBarCart();
    },
    openLocalisationDialog() {
      this.$store.commit('shop/localisation/toggleDialog', true);
    },
    linkWrapperMouseEnter(index) {
      this.linkWrapperHovred = index;
    },
    linkWrapperMouseLeave() {
      this.linkWrapperHovred = -1;
    },
    setStickyState() {
      let isSticky = false;

      if (window.scrollY > 100) {
        isSticky = true;
      }
      this.$store.commit('shared/menu/setMenuSticky', isSticky);
    },
    stickyState() {
      if (process.client) {
        window.addEventListener('scroll', (e) => {
          this.setStickyState();
        });
      }
    },
    getRightItem(idCategory) {
      if (idCategory === 138) {
        return {
          title: 'Focus',
          items: [
            {
              text: this.$t('Le lin'),
              link: this.localePath('/le-lin-189'),
              image: require('assets/img/lin.jpg'),
            },
            {
              text: this.$t('Les essentiels'),
              link: this.localePath('/essentiels-179'),
              image: require('assets/img/essentiels.jpg'),
            },
          ],
        };
      } else if (idCategory === 180) {
        return {
          title: 'BEST SELLERS',
          items: [
            {
              text: this.$t('BEST SELLERS'),
              link: this.localePath('/meilleures-ventes-162'),
              image: require('assets/img/best-seller.jpg'),
            },
          ],
        };
      }
    },
  },
  watch: {
    $route(to, from) {
      this.linkWrapperHovred = -1;
    },
  },
  components: {
    SearchIcon,
    MenuIcon,
    XIcon,
    SubCategory,
    SubCategoryLookBook,
    SubCategoryMark,
    DropDownMenu,
    MiniCart,
    UserCheckIcon,
    UserIcon,
    IconFavorite,
    IconCart,
    ChevronDownIcon,
  },
};
