import { render, staticRenderFns } from "./PayPalForm.vue?vue&type=template&id=5ff303ec"
import script from "./PayPalForm.vue?vue&type=script&lang=js"
export * from "./PayPalForm.vue?vue&type=script&lang=js"
import style0 from "./PayPalForm.vue?vue&type=style&index=0&id=5ff303ec&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports